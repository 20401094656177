import "./App.css";
import Home from "./Components/home/Home";
import About from "./Components/about/About";
import Work from "./Components/work/Work";
import Testimonial from "./Components/testimonial/Testimonial";
import Contact from "./Components/contact/Contact";
import Footer from "./Components/footer/Footer";
import Startup from "./Components/startup/Startup";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import TermsandConditions from "./TermsandConditions";
import Privacypolicy from "./Privacypolicy";
import RegisterForm from "./Components/register/Register";
import { ToastContainer } from "react-bootstrap";
function App() {
  const location = useLocation();
  return (
    <div className="App">
      {location.pathname === "/Terms&Conditions" ||
      location.pathname === "/Privacypolicy" ||
      location.pathname === "/RegisterPage" ? (
        <Routes>
          <Route path="/Terms&Conditions" element={<TermsandConditions />} />
          <Route path="/Privacypolicy" element={<Privacypolicy />} />
          <Route path="/RegisterPage" element={<RegisterForm />} />
          <Route path="/" element={<Home />} />
        </Routes>
      ) : (
        <>
          <ToastContainer />
          <Home />
          <About />
          {/* <Experience /> */}
          <Work />
          <Startup />
          <Testimonial />
          <Contact />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
