// import React from "react";
// import Logo from "../../Assets/2 1.png";
// import { FaInstagram } from "react-icons/fa"; // Import Instagram icon

// import { SiLinkedin } from "react-icons/si";
// import { BsYoutube } from "react-icons/bs";
// import { FaFacebookF } from "react-icons/fa";
// import "./footer.css";
// const Footer = () => {
//   return (
//     <div className="footer-wrapper">
//       <div className="footer-section-one">
//         <div className="footer-logo-container">
//           <img src={Logo} alt="" />
//         </div>
//         <div className="footer-icons">
//           <FaInstagram />
//           <SiLinkedin />
//           <BsYoutube />
//           <FaFacebookF />
//         </div>
//       </div>
//       <div className="footer-section-two">
//         <div className="footer-section-columns">
//           <p className="main-container-footer footer-icons ">+91 90423 86429</p>
//           <p className="main-container-footer footer-icons ">
//             {/* hrstartuphirers@cdp360.com */}
//             hr@startuphirers.com
//           </p>
//         </div>
//         <div className="footer-section-columns">
//           <p className="main-container-footer footer-icons ">
//             Terms & Conditions
//           </p>
//           <p className="main-container-footer footer-icons "> Privacy Policy</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Footer;
import React from "react";
import Logo from "../../Assets/2 1.png";
import { FaInstagram } from "react-icons/fa";

import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import "./footer.css";
const Footer = () => {
  const handleclick = () => {
    window.location.assign("/Terms&Conditions");
  };
  const handleclicks = () => {
    window.location.assign("/Privacypolicy");
  };
  return (
    <footer class="text-center text-lg-start" style={{ background: "#97144d" }}>
      <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div class="me-5 d-none d-lg-block">
          <span style={{ marginLeft: "7%" }}>
            <img src={Logo} alt="" style={{ width: "40%" }} />
          </span>
        </div>

        <div className="footer-icons">
          <a
            href="https://www.instagram.com/startup_hirers/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FaInstagram size={30} />
          </a>
          <a
            href="https://x.com/Startuphirers"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FaXTwitter size={30} />
          </a>
          <a
            href="https://www.linkedin.com/company/startup-hirers"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <SiLinkedin size={30} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCWIQSsKqFpA5lF-bxrtNY4Q"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <BsYoutube size={30} />
          </a>
          <a
            href="https://www.facebook.com/startuphirers/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <FaFacebookF size={30} />
          </a>
        </div>
      </section>

      <section class="" style={{ background: "#97144d" }}>
        <div class=" text-center text-md-start mt-5">
          <div class="row mt-3">
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 class="text-uppercase fw-bold mb-4 text-white">
                <p>About</p>
              </h6>
              <p className="text-white">
                We fix this gap of and handholding effective hire for startups &
                companies at the 0 - 3 years hire segment. We also do campus
                hire on your company behalf and put them on the employability
                track with pre-joining training needs.
              </p>
            </div>

            {/* <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 class="text-uppercase fw-bold mb-4">Products</h6>
              <p>
                <a href="#!" class="text-reset">
                  Angular
                </a>
              </p>
              <p>
                <a href="#!" class="text-reset">
                  React
                </a>
              </p>
              <p>
                <a href="#!" class="text-reset">
                  Vue
                </a>
              </p>
              <p>
                <a href="#!" class="text-reset">
                  Laravel
                </a>
              </p>
            </div> */}

            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 class="text-uppercase fw-bold mb-4 text-white">
              </h6>
              <p className="text-white" style={{ cursor: "pointer" }}>
                <a
                  href="#"
                  className="text-white"
                  onClick={(e) => {
                    e.preventDefault();
                    handleclick();
                  }}
                >
                  Terms & Conditions
                </a>
              </p>

              <p className="text-white" style={{ cursor: "pointer" }}>
                <a
                  href="#"
                  className="text-white"
                  onClick={(e) => {
                    e.preventDefault();
                    handleclicks();
                  }}
                >
                  Privacy Policy
                </a>
              </p>

              {/* <a
                href="https://recruiter-form.startuphirers.com/Privacy%20-Policy.html"
                className="text-white"
                style={{ cursor: "pointer" }}
              >
                Privacy Policy
              </a> */}
            </div>

            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 class="text-uppercase fw-bold mb-4 text-white">Contact</h6>
              <p className="text-white">+91 90423 86429</p>
              <p className="text-white">hr@startuphirers.com</p>
            </div>
          </div>
        </div>
      </section>

     
    </footer>
  );
};

export default Footer;
