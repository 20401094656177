// import React, { useState } from "react";
// import "./register.css";
// import BannerBackground from "../../Assets/home-banner-background-2.png";
// import BannerImage from "../../Assets/Group 14.png";
// import Button from "react-bootstrap/Button";
// import { Modal, Form } from "react-bootstrap";
// import { useForm } from "react-hook-form";
// import bannerImge from "../../Assets/2544344.png";
// function Register() {
//   const [show, setShow] = useState(false);
//   const [successMessage, setSuccessMessage] = useState(false);
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     reset,
//     setValue,
//   } = useForm();

//   const handleClose = () => setShow(false);
//   // const handleShow = () => setShow(true);

//   const handleShow = () => {
//     window.location.assign("/RegisterPage");
//   };

//   const onSubmit = async (data) => {
//     const form = new FormData();

//     for (const key in data) {
//       if (key !== "cvUpload") {
//         form.append(key, data[key]);
//       }
//     }

//     if (data.cvUpload && data.cvUpload[0]) {
//       form.append("cvUpload", data.cvUpload[0]);
//     }

//     try {
//       const response = await fetch(
//         "https://prod-lms-courseengine-backend.cdp360.org/cdp/startupHire",
//         {
//           method: "POST",
//           body: form,
//         }
//       );

//       if (response.ok) {
//         setSuccessMessage(true);
//         setTimeout(() => {
//           setShow(false);
//           setSuccessMessage(false);
//           reset();
//         }, 1000);
//       } else {
//         console.error("Form submission failed:", response.statusText);
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     }
//   };

//   return (
//     <div
//       style={{
//         width: "100%",
//         padding: "10px",
//         background: "red",
//         height: "100vh",
//       }}
//     >
//       <Form>
//         <div style={{ display: "flex" }}>
//           <div
//             style={{
//               width: "50%",
//               display: "flex",
//               gap: "20px",
//               padding: "50px",
//             }}
//           >
//             <div>
//               <Form.Group className="mb-3" controlId="formBasicEmail">
//                 <Form.Label>Email address</Form.Label>
//                 <Form.Control type="email" placeholder="Enter email" />
//               </Form.Group>
//             </div>
//             <div>
//               <Form.Group className="mb-3" controlId="formBasicEmail">
//                 <Form.Label>Email address</Form.Label>
//                 <Form.Control type="email" placeholder="Enter email" />
//               </Form.Group>
//             </div>
//           </div>
//           <div style={{ width: "50%", padding: "30px" }}>
//             <img src={bannerImge} />
//           </div>
//         </div>
//       </Form>
//     </div>
//   );
// }

// export default Register;
import React, { useState } from "react";
import "./register.css";
import banner from "../../Assets/register.jpeg";
import BannerBackground from "../../Assets/home-banner-background-2.png";
import BannerImage from "../../Assets/Group 14.png";
import Navbar from "../navbar/Navbar";
import { FiArrowRight } from "react-icons/fi";
import Button from "react-bootstrap/Button";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
function Register() {
  const [show, setShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);

  const handleShow = () => {
    window.location.assign("/RegisterPage");
  };
  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/"); 
  };

  const onSubmit = async (data) => {
    const form = new FormData();

    for (const key in data) {
      if (key !== "cvUpload") {
        form.append(key, data[key]);
      }
    }

    if (data.cvUpload && data.cvUpload[0]) {
      form.append("cvUpload", data.cvUpload[0]);
    }

    try {
      const response = await fetch(
        "https://prod-lms-courseengine-backend.cdp360.org/cdp/startupHire",
        {
          method: "POST",
          body: form,
        }
      );

      if (response.ok) {
        setShowModal(true); // Show success modal
        reset(); // Reset the form
      } else {
        console.error("Form submission failed:", response.statusText);
        toast.error("Form submission failed. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };
  return (
    <div className="signin-container">
      <ToastContainer />
      <div className="header-container"></div>
      <div className="sidein-body-container">
        <div className="sidein-body-content">
          <div style={{ width: "100%" }}>
            <img src={banner} className="imgsection" />
          </div>
          <div className="sidein-field-content">
            <div className="text-section">Fresher's Registration</div>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row" style={{ marginTop: "3%" }}>
                <div className="col-12 col-md-6">
                  <Form.Group className="mb-3" style={{ textAlign: "start" }}>
                    <Form.Label style={{ color: "white" }}>Name *</Form.Label>
                    <Form.Control
                      type="text"
                      {...register("name", { required: "Name is required." })}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ color: "#d4f211" }}
                    >
                      {errors.name?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" style={{ textAlign: "start" }}>
                    <Form.Label style={{ color: "white" }}>
                      Mobile Number *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register("phoneNo", {
                        required: "Mobile number is required.",
                        pattern: {
                          value: /^\d{10}$/,
                          message: "Enter a valid 10-digit mobile number.",
                        },
                      })}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 10);
                      }}
                      isInvalid={!!errors.phoneNo}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ color: "#d4f211" }}
                    >
                      {errors.phoneNo?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" style={{ textAlign: "start" }}>
                    <Form.Label style={{ color: "white" }}>Email *</Form.Label>
                    <Form.Control
                      type="email"
                      {...register("email", {
                        required: "Email is required.",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Enter a valid email.",
                        },
                      })}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ color: "#d4f211" }}
                    >
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" style={{ textAlign: "start" }}>
                    <Form.Label style={{ color: "white" }}>City *</Form.Label>
                    <Form.Control
                      type="text"
                      {...register("city", {
                        required: "City is required.",
                        minLength: {
                          value: 3,
                          message: "City must have at least 3 characters.",
                        },
                      })}
                      isInvalid={!!errors.city}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ color: "#d4f211" }}
                    >
                      {errors.city?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-12 col-md-6">
                  <Form.Group className="mb-3" style={{ textAlign: "start" }}>
                    <Form.Label style={{ color: "white" }}>
                      Graduation *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register("graduation", {
                        required: "Graduation is required.",
                      })}
                      isInvalid={!!errors.graduation}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ color: "#d4f211" }}
                    >
                      {errors.graduation?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" style={{ textAlign: "start" }}>
                    <Form.Label style={{ color: "white" }}>
                      Specialisation *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register("specialisation", {
                        required: "Specialisation is required.",
                      })}
                      isInvalid={!!errors.specialisation}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ color: "#d4f211" }}
                    >
                      {errors.specialisation?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" style={{ textAlign: "start" }}>
                    <Form.Label style={{ color: "white" }}>
                      Year of Passing *
                    </Form.Label>
                    <Form.Select
                      {...register("yearOfPassing", {
                        required: "Year of passing is required.",
                      })}
                      isInvalid={!!errors.yearOfPassing}
                    >
                      <option value="">Select Year</option>
                      <option value="Pursuing Final Year">
                        Pursuing Final Year *
                      </option>
                      <option value="2023-2024">2023-2024</option>
                      <option value="2022-2023">2022-2023</option>
                      <option value="2021-2022">2021-2022</option>
                      <option value="Earlier">Earlier</option>
                    </Form.Select>
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ color: "#d4f211" }}
                    >
                      {errors.yearOfPassing?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" style={{ textAlign: "start" }}>
                    <Form.Label style={{ color: "white" }}>
                      College Name *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register("collegeName", {
                        required: "College name is required.",
                      })}
                      isInvalid={!!errors.collegeName}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ color: "#d4f211" }}
                    >
                      {errors.collegeName?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <Form.Group className="mb-3" style={{ textAlign: "start" }}>
                <Form.Label style={{ color: "white" }}>
                  Upload Resume *
                </Form.Label>
                <Form.Control
                  type="file"
                  {...register("cvUpload", {
                    required: "Please upload your resume.",
                  })}
                  isInvalid={!!errors.cvUpload}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ color: "#d4f211" }}
                >
                  {errors.cvUpload?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                style={{
                  backgroundColor: "black",
                  width: "100%",
                  borderRadius: "5px",
                  border: "none",
                  padding: "10px",
                  fontWeight: "600",
                }}
                className="mt-3"
                type="submit"
              >
                Register
              </Button>
            </Form>
            <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Thanks for Registering with us. Our Placement team will reach you to further assist on your hiring needs.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
