import React from "react";

const TermsandConditions = () => {
  return (
    <div className="container mt-5">
      <div
        className=" alert-danger text-white p-5 mb-5 rounded"
        style={{ background: "#97144d" }}
      >
        <h3 className="text-center text-white mb-2">Terms & Conditions</h3>
        <ul style={{lineHeight:"35px"}}>
          <li>
            If a candidate secures a job placement during the training or
            internship period, the stipend will no longer be provided for the
            remaining duration.
          </li>
          <li>
            The company reserves the right to discontinue the internship program
            for candidates who are placed before completing the training or
            internship.
          </li>
          <li>
            Candidates must maintain consistent attendance, punctuality, and
            good behavior throughout the program. Failure to comply with these
            requirements may result in termination of their enrollment, with no
            refund provided in such cases.
          </li>
          <li>
            Stipends are strictly tied to active participation in the program;
            any unauthorized breaks or withdrawals may lead to discontinuation
            of stipend payments.
          </li>
          <li>
            All program fees are non-refundable, and any decision to withdraw
            from the program must be communicated formally.
          </li>
          <li>
            While we provide career mentorship and placement assistance, job
            placements are not guaranteed or assured.
          </li>
        </ul>

        <div className="" style={{marginLeft:"2%"}}>
          <p>
            <span className="fw-bold">Registration & Onboarding: </span>
            Recruiters must register with accurate business and contact details,
            including proof of legal status (e.g., business registration). Only
            verified recruiters will gain access to intern profiles.
          </p>
          <p>
            <span className="fw-bold">Access to Candidates: </span>Candidate
            information provided by the platform is solely for recruitment
            purposes and must not be used for any other activity.
          </p>
          <p>
            <span className="fw-bold">Candidate Communication: </span>
            Communication with candidates must remain professional and ethical.
            Recruiters are required to inform candidates of their application
            status promptly.
          </p>
          <p>
            <span className="fw-bold">Platform Fees & Payment Terms: </span>
            Recruiters are subject to platform usage fees, as outlined in their
            subscription or service agreement. Late or non-payment may result in
            restricted access.
          </p>
          <p>
            <span className="fw-bold">Confidentiality & Data Usage: </span>
            Candidate data must be kept confidential and used only for
            recruitment purposes. Sharing data with third parties without
            consent is prohibited.
          </p>
          <p>
            <span className="fw-bold">Code of Conduct: </span>
            Recruiters must ensure a safe, inclusive, and respectful working
            environment for interns. Reports of misconduct may lead to
            suspension or termination of platform access.
          </p>
          <p>
            <span className="fw-bold">Termination of Access: </span>
            The platform may suspend or terminate access for breaches of terms,
            unethical practices, or non-payment of fees. Recruiters may
            terminate their association by providing written notice as per the
            service agreement.
          </p>
          <p>
            <span className="fw-bold">Dispute Resolution: </span>
            The platform will not mediate disputes between recruiters and
            interns but may provide relevant documentation upon request.
            Platform-related disputes must be resolved through mediation or
            arbitration.
          </p>
          <p>
            <span className="fw-bold">Indemnification: </span>
            Recruiters agree to indemnify and hold the platform harmless from
            any claims or liabilities arising from the internship or recruitment
            process.
          </p>
          <p>
            <span className="fw-bold">Governing Law: </span>
            These terms are governed by the laws of the jurisdiction in which
            the platform operates.
          </p>
          <p>
            <span className="fw-bold">Modifications to Terms: </span>
            The platform may update these terms at any time. Recruiters will be
            notified of changes and may discontinue their use of the platform if
            they disagree with the updated terms.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsandConditions;
