import React from "react";
import AboutBackgroundImage from "../../Assets/aboutimg.png";
import "./about.css";
const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <h1 className="primary-heading">
        Fuelling the 0 to 3 years Employment Revolution
          {/* Fueling the Startup Revolution */}

        </h1>
        <p className="para-text-about">
          {/* We are dedicated to empowering job seekers by aligning them with
          market-demand skills, providing training, and preparing them for
          deployment in startups and mid-sized companies. */}
          We are dedicated to empowering job seekers by aligning them with market need and demand skills, providing training, and preparing them for deployment in startups and tech companies.
          Companies that often need entry-level resources 0 to 3 year hire ideally, expect to be productive with in few weeks. We understand this concern and have come up with custom pre-onboarding formalities, trained and groomed as per your unique needs. 
        </p>
        <p className="para-text-about ">
          {/* These companies often need entry-level resources to be productive
          quickly and typically lack extensive training budgets or the capacity
          for low productivity during onboarding. Our approach ensures they
          receive job-ready candidates who can contribute from day one. */}
          Our approach ensures companies receive job-ready & deployable candidates who can contribute from day one.
        </p>
      </div>
    </div>
  );
};

export default About;
