import React, { useState } from "react";
import "./contact.css";

const Contact = () => {
  return (
    <div className="contact-page-wrapper">
      <h1 className="primary-heading question">
        Got a 0 to 3 Years Hiring Requirements ?
      </h1>
      <h1 className="primary-heading">We're Here to Help!</h1>
      <div className="contact-form-container">
        <p className="secondary-button">
          <a
            href="https://wa.me/918925841210?text=Welcome%20there!%20Startup%20Hirers"
            style={{
              textDecoration: "none",
              color: "white",
              borderRadius: "5px",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            Let's Talk
          </a>
        </p>
      </div>
    </div>
  );
};

export default Contact;
