import React from "react";

const Privacypolicy = () => {
  return (
    <div className="container mt-5">
      <div
        className=" alert-danger text-white p-5 mb-5 rounded"
        style={{ background: "#97144d" }}
      >
        <h3 className="text-center mb-2">Privacy Policy</h3>
        <p>
          This website is owned by Startup Hirers. We value your privacy and are
          committed to safeguarding the information you share with us. At
          Startup Hirers, we ensure transparency in how we collect, store, and
          use your information. Please note that this policy applies solely to
          our website and services and does not extend to third-party websites,
          which may have separate terms and conditions. By using our website,
          you agree to the terms of this Privacy Policy.
        </p>
        <h5>Information We Collect</h5>
        <p>
          You can browse our website without disclosing personal information.
          However, during certain interactions—such as signing up for the
          internship program or filling out forms—you may provide details about
          yourself. By doing so, you consent to our collection and usage of this
          information for the purposes outlined below.
        </p>
        <h5>The types of information we collect include:</h5>
        <ul>
          <li>
            <p>
              <span className="fw-bold">Personal Details :</span> Name, email
              ID, mobile number, location preferences, and program preferences.
            </p>
          </li>
          <li>
            <p>
              <span className="fw-bold">Program Details: </span>Selection of the
              internship program, preferred start date, and any other
              program-specific details you provide.
            </p>
          </li>

          <li>
            <p>
              <span className="fw-bold">Communication Records: </span>Emails,
              direct inquiries, and messages sent to us.
            </p>
          </li>
          <li>
            <p>
              <span className="fw-bold">Website Data: </span>Information
              automatically recorded through cookies and server logs, such as
              your IP address, browser type, referring/exit pages, and pages
              visited on our website. This data does not personally identify you
              and is collected to analyze user trends and enhance the website
              experience.
            </p>
          </li>
        </ul>
        <h4>Usage of Information</h4>
        <h5>The information we collect is used to:</h5>
        <div style={{ marginLeft: "1%", marginTop: "1%" }}>
          <p>
            1. Explain our internship programs, services, and processes to you
            in detail.
          </p>
          <p>
            2. Enhance the quality of our offerings based on user feedback and
            preferences.
          </p>
          <p>
            3. Facilitate communication through our program counselors, who may
            contact you to provide guidance or program details.
          </p>
          <p>
            4. Analyze website traffic, improve navigation, and ensure a
            personalized experience for users.
          </p>
          <p>
            5. Measure the effectiveness of our outreach and marketing efforts.
          </p>
        </div>
        <h4>Your Consent</h4>
        <p>
          By submitting your information to Startup Hirers, you acknowledge that
          you have reviewed this Privacy Policy and agree to the outlined usage
          of your information.
        </p>
        <p>
          Our tele-counselors may reach out to you to explain our programs and
          services in detail. Rest assured, your information is handled with
          care, and your privacy is our priority. If you have any questions or
          concerns, feel free to contact us for clarification.
        </p>
      </div>
    </div>
  );
};

export default Privacypolicy;
